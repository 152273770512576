<template>
  <div>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/GMnC3SHYBjKXtNVz4lhZ_je3e_OdL7ZHd6FAFx8o_2gFHvptKAHWV0YpzKZOKsj4Uw=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Minibus Bus Transport Driver</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Simulation
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can enjoy driving a bus minibus in the city.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >300,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>1000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >500+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/W2u6qjppuz-wPsStlOSMRnQHa4qRNbYuwyfucQHv-vi_BW5yV9FVBhj9PyZKBTw-0w=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.demolition"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Demolition Destroyer!</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Arcade
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can demolish every building at your fingertip!</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.demolition"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >20,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>200</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >100+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.demolition&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/0Su_-xnHfabi9gtWmWTxOZZNSh7TFqkhPoVC0Kx1FOTZI8FKSYVTlzKNaB_0WhLaM2Y=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.gunshiphelicopter.battle3D"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Helicopter Gunship Simulator</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Action
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >The world's most powerful combat helicopters are at your
                  fingertips.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.gunshiphelicopter.battle3D"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >50,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>500</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >200+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.gunshiphelicopter.battle3D&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/6yXsVwKcik-9ZMFrgCG6jGFkgQ1AeFgwyjTNB-vPTYe82LzuCCbdkHdr9zqYTTThB7I=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.taxicity"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Taxi Simulator City Driving</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Simulation
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can enjoy driving a taxi in the city.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.taxicity"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >5,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>50</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >50+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.taxicity&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/qfvKmI82A3zot-54VBqCnbjCYKQkN985wHO6IuNs4f1JQ6f7EOkwEX4-0zlMrnE9m4s=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dungeon"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Dungeon Boss Hunter</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Role Playing
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can enjoy fighting in dungeons. Role-playing game for RPG Offline.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dungeon"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >5,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>50</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >50+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.dungeon&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/uQdUf0BV72ewMoY4MTIpt8MdT6uOiEVlzD6PvIXJb3z0FgVTu7ML8HC1yDw1uZyWt_0=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.bowmaster"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Bow Master: Stickman Archer</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Arcade
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >Fight stickman enemies with bow, shoot 'em up and become an archer legend.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.bowmaster"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >5,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>50</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >50+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.bowmaster&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/o1FXEw6aGsrMyMZuWaFNb2wnes5iRXRCkitqHWHDD9siW9TqKv8ZUtotdgQGxyyXE8g=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.trucksimulator3d"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Truck Simulator Transport</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Simulation
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can enjoy driving a truck in the city.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.trucksimulator3d"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >5,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>70</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >50+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.trucksimulator3d&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "games",
  components: {},
  computed: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Our Games" }]);
  }
};
</script>
